//@ts-nocheck
import { createApp } from "vue";
import Elf from "@smart/elf";
import App from "./App.vue";
import { setupStore } from "./stores";
import { setupRouter } from "./router";

import "@/styles/tailwind.css";
import "@smart/elf/lib/elf.less";
import "@/styles/common.css";
// import '@/router/routerUtils';
// import VueRouter from "vue-router";

const app = createApp(App);
app.use(Elf);

// 挂载store
setupStore(app);

// 挂载路由
setupRouter(app);


// // 获取原型对象push函数
// const originalPush = VueRouter.prototype.push;

// // 获取原型对象replace函数
// const originalReplace = VueRouter.prototype.replace;

// // 修改原型对象中的push函数
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

// // 修改原型对象中的replace函数
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch((err) => err);
// };

app.mount("#app");
