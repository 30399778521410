import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/fileType/pdf.png'
import _imports_1 from '@/assets/fileType/excel.png'
import _imports_2 from '@/assets/fileType/word.png'
import _imports_3 from '@/assets/fileType/rar.png'
import _imports_4 from '@/assets/fileType/gif.png'


const _withScopeId = n => (_pushScopeId("data-v-36ef3f60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col min-h-full" }
const _hoisted_2 = { class: "bg-white p-3 mb-3 overflow-hidden" }
const _hoisted_3 = { class: "bg-white p-6 flex-auto" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src", "onClick"]
const _hoisted_10 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_input = _resolveComponent("elf-input")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_space = _resolveComponent("elf-space")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_table_column = _resolveComponent("elf-table-column")!
  const _component_elf_table = _resolveComponent("elf-table")!
  const _component_userAddOrUpdate = _resolveComponent("userAddOrUpdate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_elf_form, {
        layout: "inline",
        model: _ctx.searchForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                style: {"width":"150px"},
                value: _ctx.searchForm.brand,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.brand) = $event)),
                placeholder: "品牌",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                style: {"width":"150px"},
                value: _ctx.searchForm.model,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchForm.model) = $event)),
                placeholder: "车型",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                style: {"width":"150px"},
                value: _ctx.searchForm.application,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchForm.application) = $event)),
                placeholder: "应用业务",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_space, { size: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_button, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reload()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("查询")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_button, {
                    type: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addOrUpdateMenus('1'))),
                    style: {"color":"white"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("新增")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_elf_table, {
        "data-source": _ctx.dataSource,
        pagination: {
          current: _ctx.pagination.current,
          total: _ctx.pagination.total,
          pageSize: _ctx.pagination.pageSize,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条记录`,
        },
        "row-key": (record) => record.id,
        onChange: _ctx.onTableChange,
        onRowClassName: _cache[6] || (_cache[6] = (_record, index) => (index % 2 === 1 ? 'table-striped' : null))
      }, {
        expandedRowRender: _withCtx(({ record }) => [
          _createVNode(_component_elf_table, {
            style: {"margin":"0"},
            "data-source": record.smartResourcesEntities,
            pagination: false,
            onRowClassName: _cache[5] || (_cache[5] = (_record, index) => (index % 2 === 1 ? 'table-striped' : null))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_table_column, {
                key: "name",
                title: "文件名称",
                "data-index": "name",
                align: "center"
              }),
              _createVNode(_component_elf_table_column, {
                key: "resourcesUrl",
                title: "文件资源地址",
                "data-index": "resourcesUrl",
                align: "center"
              }, {
                default: _withCtx(({ record }) => [
                  (record.name && record.name.split('.').pop() == 'pdf')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "w-5 h-5 cursor-pointer",
                        src: _imports_0,
                        alt: "",
                        onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                      }, null, 8, _hoisted_4))
                    : ((record.name && record.name.split('.').pop() == 'xls') || (record.name && record.name.split('.').pop() == 'xlsx'))
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "w-5 h-5 cursor-pointer",
                          src: _imports_1,
                          alt: "",
                          onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                        }, null, 8, _hoisted_5))
                      : ((record.name && record.name.split('.').pop() == 'doc') || (record.name && record.name.split('.').pop() == 'docx'))
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "w-5 h-5 cursor-pointer",
                            src: _imports_2,
                            alt: "",
                            onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                          }, null, 8, _hoisted_6))
                        : ((record.name && record.name.split('.').pop() == 'zip') || (record.name && record.name.split('.').pop() == 'rar'))
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 3,
                              class: "w-5 h-5 cursor-pointer",
                              src: _imports_3,
                              alt: "",
                              onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                            }, null, 8, _hoisted_7))
                          : ((record.name && record.name.split('.').pop() == 'gif'))
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 4,
                                class: "w-5 h-5 cursor-pointer",
                                src: _imports_4,
                                alt: "",
                                onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                              }, null, 8, _hoisted_8))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 5,
                                src: record.resourcesUrl,
                                alt: "",
                                class: "w-5 h-5 cursor-pointer",
                                onClick: ($event: any) => (_ctx.openUrl(record.resourcesUrl))
                              }, null, 8, _hoisted_9))
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_elf_table_column, {
                key: "version",
                title: "文件版本号",
                "data-index": "version",
                align: "center"
              })
            ]),
            _: 2
          }, 1032, ["data-source"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_elf_table_column, {
            key: "brand",
            title: "品牌",
            "data-index": "brand",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "model",
            title: "车型",
            "data-index": "model",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "application",
            title: "应用业务",
            "data-index": "application",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "createDate",
            title: "创建时间",
            "data-index": "createDate",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "realName",
            title: "创建人",
            "data-index": "realName",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            title: "操作",
            width: 100,
            align: "center"
          }, {
            default: _withCtx(({ record }) => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_elf_button, {
                  type: "link",
                  block: "",
                  onClick: ($event: any) => (_ctx.addOrUpdateMenus(record))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("修改")
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_elf_button, {
                  type: "link",
                  block: "",
                  onClick: ($event: any) => (_ctx.deletebtn(record.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 删除 ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-source", "pagination", "row-key", "onChange"])
    ]),
    (_ctx.userAddOrUpdateVisible)
      ? (_openBlock(), _createBlock(_component_userAddOrUpdate, {
          key: 0,
          ref: "userAddOrUpdateRefs",
          getdata: _ctx.getdata
        }, null, 8, ["getdata"]))
      : _createCommentVNode("", true)
  ]))
}