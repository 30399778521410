import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-full" }
const _hoisted_2 = { class: "bg-white p-3 mb-3 overflow-hidden" }
const _hoisted_3 = { class: "bg-white p-6 flex-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_input = _resolveComponent("elf-input")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_select = _resolveComponent("elf-select")!
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_space = _resolveComponent("elf-space")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_table_column = _resolveComponent("elf-table-column")!
  const _component_elf_table = _resolveComponent("elf-table")!
  const _component_userAddOrUpdate = _resolveComponent("userAddOrUpdate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_elf_form, {
        layout: "inline",
        model: _ctx.searchForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                style: {"width":"150px"},
                value: _ctx.searchForm.username,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.username) = $event)),
                placeholder: "用户名",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_select, {
                style: {"width":"150px"},
                value: _ctx.searchForm.gender,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchForm.gender) = $event)),
                options: _ctx.genderOption,
                placeholder: "性别",
                "allow-clear": ""
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_space, { size: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_button, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reload()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("查询")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_button, {
                    type: "primary",
                    style: {"color":"#fff"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addOrUpdateMenus('1')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("新增")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_elf_table, {
        "data-source": _ctx.dataSource,
        pagination: {
            current: _ctx.pagination.current,
            total:_ctx.pagination.total,
            pageSize: _ctx.pagination.pageSize,
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条记录`,
          },
        "row-key": (record) => record.id,
        onChange: _ctx.onTableChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_table_column, {
            key: "username",
            title: "登录名",
            "data-index": "username",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "email",
            title: "邮箱",
            "data-index": "email",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "mobile",
            title: "手机号",
            "data-index": "mobile",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "gender",
            title: "性别",
            "data-index": "gender",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "status",
            title: "状态",
            "data-index": "status",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "createDate",
            title: "创建时间",
            "data-index": "createDate",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            title: "操作",
            align: "center"
          }, {
            default: _withCtx(({ record }) => [
              _createVNode(_component_elf_button, {
                type: "link",
                block: "",
                onClick: ($event: any) => (_ctx.addOrUpdateMenus(record.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("修改")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-source", "pagination", "row-key", "onChange"])
    ]),
    (_ctx.userAddOrUpdateVisible)
      ? (_openBlock(), _createBlock(_component_userAddOrUpdate, {
          key: 0,
          ref: "userAddOrUpdateRefs"
        }, null, 512))
      : _createCommentVNode("", true)
  ]))
}