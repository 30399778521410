import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_radio = _resolveComponent("elf-radio")!
  const _component_elf_radio_group = _resolveComponent("elf-radio-group")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_input = _resolveComponent("elf-input")!
  const _component_elf_tree_select = _resolveComponent("elf-tree-select")!
  const _component_elf_input_number = _resolveComponent("elf-input-number")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_modal = _resolveComponent("elf-modal")!

  return (_openBlock(), _createBlock(_component_elf_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.dataForm.id ? '修改' : '新增',
    width: "60%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_elf_button, {
        key: "back",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_elf_button, {
        key: "submit",
        type: "primary",
        style: {"color":"#fff"},
        loading: _ctx.loading,
        onClick: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createTextVNode("确认")
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_elf_form, {
        "label-col": { span: 4 },
        wrapperCol: { span: 18 }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, {
            name: "menuType",
            label: "类型"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_radio_group, {
                value: _ctx.dataForm.menuType,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataForm.menuType) = $event)),
                disabled: !!_ctx.dataForm.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_radio, { value: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode("菜单")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_radio, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode("按钮")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "name",
            label: "菜单名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.name,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataForm.name) = $event)),
                placeholder: "菜单名称"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, { label: "上级菜单" }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_tree_select, {
                value: _ctx.dataForm.pid,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataForm.pid) = $event)),
                style: {"width":"100%"},
                "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                "tree-data": _ctx.menuOptions,
                placeholder: "上级菜单",
                "tree-default-expand-all": "",
                "field-names": {
            label: 'title',
            value: 'value',
            children: 'children',
          },
                "allow-clear": "",
                "tree-node-filter-prop": "label"
              }, null, 8, ["value", "tree-data"])
            ]),
            _: 1
          }),
          (_ctx.dataForm.menuType === 0)
            ? (_openBlock(), _createBlock(_component_elf_form_item, {
                key: 0,
                name: "url",
                label: "路由"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_input, {
                    value: _ctx.dataForm.url,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataForm.url) = $event)),
                    placeholder: "路由"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_elf_form_item, {
            name: "sort",
            label: "排序"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input_number, {
                value: _ctx.dataForm.sort,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataForm.sort) = $event)),
                "controls-position": "right",
                min: 0,
                label: "排序"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          (_ctx.dataForm.menuType === 0)
            ? (_openBlock(), _createBlock(_component_elf_form_item, {
                key: 1,
                name: "openStyle",
                label: "打开方式"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_radio_group, {
                    value: _ctx.dataForm.openStyle,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataForm.openStyle) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_elf_radio, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("内部打开")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_elf_radio, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("外部打开")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_elf_form_item, {
            name: "permissions",
            label: "授权标识"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.permissions,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataForm.permissions) = $event)),
                placeholder: "授权标识"
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}