import { type App } from 'vue';
import { createPinia } from 'pinia';
import piniaLogger from 'pinia-logger';
import useUserStore, { type State as UserState } from './user';

const setupStore = (app: App) => {
  const pinia = createPinia();
  pinia.use(
    piniaLogger({
      expanded: false,
      disabled: process.env.NODE_ENV === 'production',
    }),
  );

  app.use(pinia);
};
export { setupStore, useUserStore };
export type { UserState };
