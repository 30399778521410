const notFound = {
  // path: '/:pathMatch(.*)*',
  // name: 'NotFound',
  // meta: {
  //   isNavigationMenu: false,
  // },
  // component: () => import(/* webpackChunkName: "404" */ '@/views/404/index.vue'),
  
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "404" */ '@/views/error.vue'),
    meta: { title: "404", isNavigationMenu: false }
  
};

export default notFound;
