import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-full" }
const _hoisted_2 = { class: "bg-white px-5 pt-5 overflow-hidden" }
const _hoisted_3 = { class: "bg-white pl-6 pr-6 pb-6 flex-auto" }
const _hoisted_4 = {
  key: 0,
  class: "border-solid border-slate-200 rounded-sm text-gray-500 text-xs pt-[2px] pb-[2px]"
}
const _hoisted_5 = {
  key: 1,
  class: "border-solid border-slate-200 rounded-sm text-gray-500 text-xs pt-[2px] pb-[2px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_space = _resolveComponent("elf-space")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_table_column = _resolveComponent("elf-table-column")!
  const _component_elf_table = _resolveComponent("elf-table")!
  const _component_menuAddOrUpdate = _resolveComponent("menuAddOrUpdate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_elf_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_elf_space, { size: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_button, {
                    type: "primary",
                    style: {"color":"#fff"},
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addOrUpdateMenus({}, '1')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("新增")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_elf_table, {
        "data-source": _ctx.dataSource,
        pagination: {
          current: _ctx.pagination.current,
          total: _ctx.pagination.total,
          pageSize: _ctx.pagination.pageSize,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条记录`,
        },
        "row-key": (record) => record.id,
        onChange: _ctx.onTableChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_table_column, {
            key: "name",
            title: "姓名",
            "data-index": "name",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "menuType",
            title: "类型",
            "data-index": "menuType",
            align: "center"
          }, {
            default: _withCtx(({ record }) => [
              (record.menuType == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "菜单"))
                : _createCommentVNode("", true),
              (record.menuType == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "按钮"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_elf_table_column, {
            key: "openStyle",
            title: "打开方式",
            "data-index": "openStyle",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "sort",
            title: "排序",
            "data-index": "sort",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "url",
            title: "路由",
            "data-index": "url",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            key: "permissions",
            title: "授权标识",
            "data-index": "permissions",
            align: "center"
          }),
          _createVNode(_component_elf_table_column, {
            title: "操作",
            align: "center"
          }, {
            default: _withCtx(({ record }) => [
              _createVNode(_component_elf_button, {
                type: "link",
                onClick: ($event: any) => (_ctx.addMenus(record, '1'))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("新增")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_elf_button, {
                type: "link",
                onClick: ($event: any) => (_ctx.addOrUpdateMenus(record, record.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("修改")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_elf_button, {
                type: "link",
                onClick: ($event: any) => (_ctx.deleteMenus(record.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("删除")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-source", "pagination", "row-key", "onChange"])
    ]),
    (_ctx.menuAddOrUpdateVisible)
      ? (_openBlock(), _createBlock(_component_menuAddOrUpdate, {
          key: 0,
          ref: "menuAddOrUpdateRef"
        }, null, 512))
      : _createCommentVNode("", true)
  ]))
}