import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-719df64d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      class: "iframe",
      src: _ctx.url,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.load && _ctx.load(...args)))
    }, null, 40, _hoisted_1)
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}