import ElfHttp from '@smart/elf-http';
import { Message } from '@smart/elf';

export const aaaHttp = new ElfHttp({
  baseURL: process.env.VUE_APP_API_URL,
  setHeader: (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers = {
        ...config.headers,
        'X-Auth-Token': token,
      };
    }

    return config;
  },
  handleResponse: (data) => {
    if (data.code === 200) {
      return Promise.resolve(data.data);
    }
    const msg = data.message || '服务器错误,请联系管理员!';
    Message.error(msg);
    return Promise.reject(new Error(msg));
  },
  toastMethod: (msg: string) => {
    Message.error(msg);
  },
});

export const bbbHttp = new ElfHttp({
  baseURL: process.env.VUE_APP_API_URL,
  handleResponse: (data) => {
    if (data.code === '200') {
      return Promise.resolve(data.data);
    }
    const msg = data.message || '服务器错误,请联系管理员!';
    Message.error(msg);
    return Promise.reject(new Error(msg));
  },
  toastMethod: (msg: string) => {
    Message.error(msg);
  },
});
