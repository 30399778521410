import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      (_ctx.enabledKeepAlive)
        ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.routerKeys[_ctx.$route.fullPath] || _ctx.$route.fullPath
            }))
          ], 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}