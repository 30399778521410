import mitt, { Emitter } from "mitt";

// type Events = {
//     foo: string;
//     bar?: number;
//   };
/**
 * 事件总线
 */
export default mitt() as Emitter<any>;
