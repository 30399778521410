import { isNavigationFailure,useRoute, type Router, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router";
import { useUserStore, type UserState } from "@/stores";

import { getToken } from "@/utils/cache";
import { routes as fullRoutes, registerDynamicToRouterAndNext, autoRegisterDynamicToRouterAndNext } from "@/router";
import { getBaseRouteToMeta, registerToRouter, toLangRoutes } from "@/utils/router";

// const check404 = (to: RouteLocationNormalized, next: NavigationGuardNext, user: UserState) => {
//   if (!to.meta.permission || (to.meta.permission && user.permissions.includes(to.meta.permission as string))) {
//     next();
//   } else {
//     // 不在permissions列表中，则去404
//     next({ name: "NotFound" });
//   }
// };

const createRouterGuards = (router: Router) => {

  router.beforeEach(async (to, from, next) => {

    if (to.meta.isNewPage) {
      if (to.query.pop !== "true") {
        next(undefined);
        return false;
      }
    }
    const userStates = useUserStore();
    const token = getToken();

    const isPop = to.query.pop === "true"; //新窗口打开内页

    if (to.path !== "/login") {
      if (userStates.routes.length) {
        if (to.name === "error") {
          const isMatched = autoRegisterDynamicToRouterAndNext(to);
          if (!isMatched) {
            userStates.appIsRender = true;
            userStates.appIsReady = true;
            next();
          }
        } else {
          userStates.appIsRender = true;
          userStates.appIsReady = true;
          next();
        }
      } else {
        if (token) {
          const resRoutes = await userStates.setPermission();
          const baseRoutes = toLangRoutes(fullRoutes);
          const mergeRoute = baseRoutes.concat(resRoutes);
          router.options.routes = mergeRoute;
          registerToRouter(router, mergeRoute);
          userStates.routes = mergeRoute;
          userStates.routeToMeta = { ...userStates.routeToMeta, ...getBaseRouteToMeta(baseRoutes) };
          setTimeout(() => {
            userStates.appIsRender = true;
            userStates.appIsLogin = true;
          }, 600);
          next({ ...to, replace: true });
        } else {

          if (isPop) {
            console.log("isPop", isPop);

            if (!to.matched.length) {
              registerDynamicToRouterAndNext({ path: to.path, query: to.query });
              userStates.appIsRender = true;
              userStates.appIsReady = true;
              next(to.fullPath);
            } else {
              userStates.appIsRender = true;
              userStates.appIsReady = true;
              console.log("我是路由守卫2",userStates.appIsReady);

              if (to.meta.requiresAuth) {
                
                next("/login");
              } else {
                console.log("我是路由守卫3",userStates.appIsReady);

                next();
              }
            }
          } else {
            console.log("我是路由守卫5",userStates.appIsReady);

            next("/login");
          }
          
        }
      }
    } else {
      console.log("我是路由守卫4",userStates.appIsReady);

      next();
    }

    // if (user.permissions.length > 0) {
    //   check404(to, next, user);
    // } else {

    //   check404(to, next, user);
    // }
  });

  router.afterEach((to, from, failure) => {
    document.title = (to?.meta?.title as string) || document.title;
    if (isNavigationFailure(failure)) {
      console.log("failed navigation", failure);
    }
  });
};

export default createRouterGuards;
