import { aaaHttp } from '../httpFactory';

type GetMockListParam = {
  pageNo: number;
};
export type GetMockListResult = {
  content: Array<{
    id: number;
    name: string;
    age: number;
    address: string;
  }>;
  total: number;
};

export function getMockList(params: GetMockListParam) {
  return aaaHttp.get<GetMockListResult>(`${window.origin}/mock-list-page-${params.pageNo}.json`);
}
