import { defineStore } from "pinia";
import { type RouteRecordRaw, useRouter } from "vue-router";
import { cloneDeep } from "lodash";
import { sleep } from "@/utils/common";
import { routes as fullRoutes } from "@/router";
import { getSysRouteMap } from "@/router";
import baseService from "@/service/baseService";
import { mergeServerRoute } from "@/utils/router";
import { removeCache } from "@/utils/cache";
import { CacheToken } from "@/constants/cacheKey";
export type State = {
  username: string;
  // permissions: Array<string>;
  authRouters: Array<RouteRecordRaw>;
  permissions: any;
  user: any;
  dicts: any;
  routeToMeta: any;
  menus: any;
  routes: any;
  showMenu: Boolean;
  appIsRender: Boolean;
  appIsReady: Boolean;
  appIsLogin: Boolean;
  collapseSidebar: Boolean;
};

const deleteRouteWithEmptyChildren = (routes: Array<RouteRecordRaw>) => {
  // 如果路由的children为空数组，则该路由不能展示在菜单中，需要从深处递归剔除
  for (let i = routes.length - 1; i >= 0; i -= 1) {
    const { children } = routes[i];

    if (children) {
      if (children.length > 0) {
        deleteRouteWithEmptyChildren(children);
      }
      if (children.length === 0) {
        routes.splice(i, 1);
      }
    }
  }
};

const router = useRouter();

const useUserStore = defineStore({
  id: "user",
  state: (): State => ({
    showMenu: false,
    appIsLogin: false, //是否登录
    appIsRender: false, //app是否开始渲染内容
    appIsReady: false, //app数据是否就绪
    collapseSidebar: false, //菜单展开

    username: "",
    permissions: [],
    authRouters: [],
    user: {
      createDate: "",
      deptId: "",
      deptName: "",
      email: "",
      gender: 0,
      headUrl: "",
      id: "",
      mobile: "",
      postIdList: "",
      realName: "",
      roleIdList: "",
      status: 0,
      superAdmin: 0,
      username: "",
    }, //用户信息
    dicts: [], //字典
    routes: [], //最终的路由集合
    menus: [], //菜单集合
    routeToMeta: {}, //url对应标题meta信息
  }),

  actions: {
    async setPermission() {
      return Promise.all([
        baseService.get("/sys/menu/nav"), //加载菜单
        baseService.get("/sys/menu/permissions"), //加载权限
        baseService.get("/sys/user/info"), //加载用户信息
        // baseService.get("/sys/dict/type/all") //加载字典
      ]).then(([menus, permissions, user]) => {
        if (user.code !== 0) {
          console.error("初始化用户数据错误", user.msg);
        }
        console.log("menus", menus);
        console.log("permissions", permissions);
        console.log("user", user);
        const [routes, routeToMeta] = mergeServerRoute(menus.data || [], getSysRouteMap());
        console.log("routes", routes);
        console.log("routeToMeta", routeToMeta);
        //@ts-ignore
        this.$patch((state) => {
          state.permissions = permissions.data || [];
          state.user = user.data || {};
          state.dicts = [];
          state.routeToMeta = routeToMeta || {};
          state.menus = [];
          state.showMenu = true;
        });
        return routes;
      });
    },
    //退出
    logout() {
      removeCache(CacheToken, true);
      //@ts-ignore
      this.$patch((state) => {
        state.appIsLogin = false;
        state.permissions = [];
        state.user = {};
        state.dicts = [];
        state.menus = [];
        state.routes = [];
      });
      location.reload()
    },
  },
});

export default useUserStore;
