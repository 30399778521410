import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_elf_menu = _resolveComponent("elf-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_elf_menu, {
      "open-keys": _ctx.openKeys,
      "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
      "selected-keys": _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
      mode: "inline",
      onClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        (_ctx.userStates.showMenu)
          ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 0,
              menus: _ctx.topHeaderMenus,
              router: false
            }, null, 8, ["menus"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["open-keys", "selected-keys", "onClick"])
  ]))
}