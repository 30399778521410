import type { App } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import type { RouteRecordRaw, RouteLocationNormalized } from "vue-router";
import createRouterGuards from "./router-guards";
import article from "./modules/article";
import activity from "./modules/activity";
import home from "./modules/home";
import notFound from "./modules/not-found";
import Login from "@/views/login/index.vue";
import Menu from "@/views/sys/menu.vue";
import Layout from "@/layout/index.vue";
import password from "@/views/sys/user-update-password.vue";
import { IObject } from "@/types/interface";

import { getBaseRouteToMeta, registerToRouter, toLangRoutes } from "@/utils/router";
interface dynamicRouteParams {
  path: string;
  query?: IObject;
  mete?: IObject;
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Layout",
    redirect: { name: "Home" },
    component: Layout,
    meta: {
      title: "首页",
    },
    children: [home],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
      isNavigationMenu: false,
    },
    children: [],
  },
  {
    path: "/user/password",
    component: () => import("@/views/sys/user-update-password.vue"),
    meta: { title: "修改密码", requiresAuth: true, isNavigationMenu: false },
  },
  // {
  //   path: "/sys",
  //   name: "Sys",
  //   component: Layout,
  //   meta: {
  //     title: "系统设置",
  //     isNavigationMenu: true,
  //   },
  //   children: [
  //     {
  //       path: "/sys/menu",
  //       name: "Menu",
  //       component: Menu,
  //       meta: {
  //         title: "菜单管理",
  //       },
  //     },
  //   ],
  // },

  notFound,
];

const router = createRouter({
  history: createWebHashHistory(""),
  routes,
});

export function setupRouter(app: App) {
  app.use(router);
  // 创建路由守卫
  createRouterGuards(router);
}

/**
 * 根据路由path转换为系统视图组件路径
 * @param path
 * @returns
 */
export const toSysViewComponentPath = (path: string): string => {
  path = path.replace("_", "-");
  // console.log("toSysViewComponentPath", `/src/views${path}.vue`);
  return `/src/views${path}.vue`;
};

/**
 * 获取系统视图路径映射
 * @returns
 */
export const getSysRouteMap = (): any => {
  const files = require.context("@/views", true, /\.vue$/);
  let pages: any = {};
  files.keys().forEach((key) => {
    // pages[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
    pages["/src/views/" + key.replace(/(\.\/|)/g, "")] = files(key).default;
  });
  return pages;
};

/**
 * 寻找视图组件
 * @param path
 * @returns
 */
const matchedSysRouteComponent = (path: string): any => {
  const sysRouteMap = getSysRouteMap();
  const component = sysRouteMap[toSysViewComponentPath(path)];
  if (!component) {
    console.error("实时注册动态路由失败，未找到组件路径", path);
  }
  return component;
};

/**
 * 实时注册动态路由并直接跳转过去
 * @param route
 */
export const registerDynamicToRouterAndNext = (route: dynamicRouteParams): void => {
  const component = matchedSysRouteComponent(route.path);
  const newRoute: RouteRecordRaw = {
    path: route.path,
    name: route.path,
    component,
    redirect: !component ? { path: "/error", query: { to: 404 }, replace: true } : "",
  };
  registerToRouter(router, [newRoute]);
  router.push(route);
};

/**
 * 自动注册路由
 * @param to
 * @returns
 */
export const autoRegisterDynamicToRouterAndNext = (to: RouteLocationNormalized): boolean => {
  if (to.redirectedFrom) {
    const path = to.redirectedFrom.path;
    const component = matchedSysRouteComponent(path);
    if (component) {
      registerToRouter(router, [
        {
          path: path,
          name: path,
          component,
          redirect: "",
        },
      ]);
      router.push(to.redirectedFrom);
      return true;
    }
  }
  return false;
};

/**
 * 转化为有效的导航路由
 * @param routes
 * @returns
 */
export const toValidRoutes = (routes: RouteRecordRaw[]): RouteRecordRaw[] => {
  const rs: RouteRecordRaw[] = [];
  routes.forEach((x: RouteRecordRaw) => {
    if (x.meta && x.meta.isNavigationMenu !== false) {
      if (x.children && x.children.length) {
        x.children = toValidRoutes(x.children);
      }
      rs.push(x);
    }
  });
  return rs;
};
export default router;
