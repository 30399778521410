import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_spin = _resolveComponent("elf-spin")!
  const _component_Layouts = _resolveComponent("Layouts")!
  const _component_BaseRouter = _resolveComponent("BaseRouter")!
  const _component_elf_config_provider = _resolveComponent("elf-config-provider")!

  return (_openBlock(), _createBlock(_component_elf_config_provider, null, {
    default: _withCtx(() => [
      (!_ctx.user.appIsRender)
        ? (_openBlock(), _createBlock(_component_elf_spin, {
            key: 0,
            class: "w-full h-full bg-white",
            indicator: _ctx.indicator
          }, null, 8, ["indicator"]))
        : _createCommentVNode("", true),
      (_ctx.user.appIsReady)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.layout == _ctx.pageTag)
              ? (_openBlock(), _createBlock(_component_Layouts, { key: 0 }))
              : (_openBlock(), _createBlock(_component_BaseRouter, { key: 1 }))
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.user.appIsReady)
        ? (_openBlock(), _createBlock(_component_BaseRouter, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}