/**
 * 页面渲染布局
 */
export enum EPageLayoutEnum {
  "page",
  "fullscreen"
}

/**
 * 系统框架事件枚举
 */
export enum EMitt {
   /**
   * 刷新tab标签页
   */
   OnReloadTabPage = "onReloadTabPage",
   /**
   * 切换左侧侧边栏
   */
   OnSwitchLeftSidebar = "onSwitchLeftSidebar",
}