import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_input = _resolveComponent("elf-input")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_radio = _resolveComponent("elf-radio")!
  const _component_elf_radio_group = _resolveComponent("elf-radio-group")!
  const _component_elf_select = _resolveComponent("elf-select")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_modal = _resolveComponent("elf-modal")!

  return (_openBlock(), _createBlock(_component_elf_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.dataForm.id ? '修改' : '新增',
    width: "60%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_elf_button, {
        key: "back",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_elf_button, {
        key: "submit",
        type: "primary",
        style: {"color":"#fff"},
        loading: _ctx.loading,
        onClick: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createTextVNode("确认")
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_elf_form, {
        "label-col": { span: 4 },
        wrapperCol: { span: 18 }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, {
            name: "username",
            label: "登录名"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.username,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataForm.username) = $event)),
                placeholder: "登录名"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "password",
            label: "密码"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.password,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataForm.password) = $event)),
                placeholder: "密码"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "confirmPassword",
            label: "确认密码"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.confirmPassword,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataForm.confirmPassword) = $event)),
                placeholder: "确认密码"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "gender",
            label: "性别"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_radio_group, {
                value: _ctx.dataForm.gender,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataForm.gender) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_radio, { value: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode("男")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_radio, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode("女")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_radio, { value: 2 }, {
                    default: _withCtx(() => [
                      _createTextVNode("保密")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "email",
            label: "邮箱"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.email,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataForm.email) = $event)),
                placeholder: "邮箱"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "mobile",
            label: "手机号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.mobile,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataForm.mobile) = $event)),
                placeholder: "手机号"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "roleIdList",
            label: "角色配置"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_select, {
                value: _ctx.dataForm.roleIdList,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataForm.roleIdList) = $event)),
                mode: "multiple",
                options: _ctx.roleList,
                placeholder: "角色配置",
                "allow-clear": ""
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "status",
            label: "状态"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_radio_group, {
                value: _ctx.dataForm.status,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataForm.status) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_elf_radio, { value: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode("停用")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_elf_radio, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode("正常")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}