/**
 * token值
 */
export const CacheToken = "CacheToken";

/**
 * 语言
 */
export const CacheLang = "CacheLang";
/**
 * 主题
 */
export const CacheTheme = "CacheTheme";
