import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  path: '/home',
  name: 'Home',
  component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
  meta: {
    title: '首页',
    icon: 'icon-home',
  },
};
export default routes;
