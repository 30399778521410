import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem", true)!
  const _component_elf_sub_menu = _resolveComponent("elf-sub-menu")!
  const _component_elf_menu_item = _resolveComponent("elf-menu-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus || [], (x) => {
      return (_openBlock(), _createElementBlock("div", {
        key: x.path
      }, [
        (x.children && x.children.length > 0)
          ? (_openBlock(), _createBlock(_component_elf_sub_menu, {
              key: x.path
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(x.meta?.title), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_MenuItem, {
                  menus: x.children
                }, null, 8, ["menus"])
              ]),
              _: 2
            }, 1024))
          : (_openBlock(), _createBlock(_component_elf_menu_item, {
              key: x.path
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", null, _toDisplayString(x.meta?.title), 1)
              ]),
              _: 2
            }, 1024))
      ]))
    }), 128))
  ]))
}