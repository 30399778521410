import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elf_input = _resolveComponent("elf-input")!
  const _component_elf_form_item = _resolveComponent("elf-form-item")!
  const _component_elf_tree = _resolveComponent("elf-tree")!
  const _component_elf_form = _resolveComponent("elf-form")!
  const _component_elf_button = _resolveComponent("elf-button")!
  const _component_elf_modal = _resolveComponent("elf-modal")!

  return (_openBlock(), _createBlock(_component_elf_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.dataForm.id ? '修改' : '新增',
    width: "60%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_elf_button, {
        key: "back",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_elf_button, {
        key: "submit",
        type: "primary",
        style: {"color":"#fff"},
        loading: _ctx.loading,
        onClick: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createTextVNode("确认")
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_elf_form, {
        "label-col": { span: 4 },
        wrapperCol: { span: 18 }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_elf_form_item, {
            name: "name",
            label: "名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataForm.name) = $event)),
                placeholder: "名称"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "remark",
            label: "备注"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_input, {
                value: _ctx.dataForm.remark,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataForm.remark) = $event)),
                placeholder: "备注"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_elf_form_item, {
            name: "menuIdList",
            label: "菜单列表"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_elf_tree, {
                "expanded-Keys": _ctx.expandedKeys,
                "onUpdate:expandedKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expandedKeys) = $event)),
                "selected-Keys": _ctx.selectedKeys,
                "onUpdate:selectedKeys": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedKeys) = $event)),
                "checked-Keys": _ctx.checkedKeys,
                "onUpdate:checkedKeys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.checkedKeys) = $event)),
                checkable: "",
                "tree-Data": _ctx.menuList
              }, null, 8, ["expanded-Keys", "selected-Keys", "checked-Keys", "tree-Data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}